<template>
  <div>
    <ModalBase :theme="'dark'" v-model="toggled">
      <template>
        <div
          class="
            component
            c-galleries__modal
            display-flex
            align-items-center
            justify-content-center
          "
        >
          <div
            v-if="selectedGallery"
            class="
              c-galleries__modal-picture
              width-100
              padding-24
              display-flex
              flex-wrap-wrap
              align-items-center
            "
          >
            <img
              :src="
                selectedGallery && selectedGallery.large
                  ? selectedGallery.large
                  : selectedGallery.medium
              "
              :alt="selectedGallery.caption + ' Modal'"
            />
          </div>
        </div>
      </template>
    </ModalBase>
  </div>
</template>
<script>
import ModalBase from "@/components/modal/ModalBase";

export default {
  name: "GalleryModal",

  components: {
    ModalBase,
  },
  props: ["value", "selectedGallery"],
  data: () => ({
    videoId: null,
  }),
  computed: {
    toggled: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {},
  watch: {
    toggled: function () {},
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
