<template>
  <div
    class="c-modal display-flex justify-content-center align-items-center"
    :class="{
      'is-open': value,
      'is-dark': this.$root.dark_mode === true,
    }"
  >
    <button
      aria-label="Close map modal"
      class="c-modal__close"
      @click="close_modal()"
    >
      <span aria-hidden="true" class="sidearm-icon sf-close-minimal"></span>
    </button>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ModalBase",
  props: ["value", "theme"],
  methods: {
    close_modal() {
      this.$emit("input", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-modal {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: $main-color;
  text-align: center;
  z-index: 200;
  &.is-open {
    opacity: 1;
    pointer-events: auto;
  }
  &.is-dark {
  }
  &__close {
    cursor: pointer;
    border: none;
    height: 60px;
    width: 60px;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--color-primary);
    color: var(--text-on-primary);
  }
}
</style>
